import { Content } from "@/models/content";
import { ContentManager } from "@/services/contentManager";

export const day2: Content[] = [
    {
        id: "06252162-5f62-43b5-9f53-b9d7a42629f6",
        text: [
            "Hi <nick name>! Welcome back to wMammogram project!"
        ],
        textStyle: [
            ["text-h5", "text-center"]
        ],
        pinkie: {
            src: require("@/assets/pinkie/hello.png"),
            animation: "pinkie-hello",
            left: true
        }
    },
    {
        id: "476571e2-d375-4517-99f2-f04141868964",
        text: [
            "Do you remember the two videos you watched?",
            "Sue Reisch, RN the Registered Nurse of the South Dakota Urban Indian Health, and Ms. Brooks, the breast cancer survivor.",
            "They emphasize the importance of getting mammograms regularly.",
            "Today, we will talk about breast cancer screening methods including mammograms. Cancer screening means looking for cancer before someone has symptoms.",
            "Are you ready?"
        ],
        nextStepText: "Yeah!"
    },
    {
        id: "5ba4ce8e-0be5-4164-94b1-45d056685ebc",
        text: [
            "A mammogram is the best screening test for finding breast cancer early, sometimes up to 3 years before it can be felt."
        ],
        video: {
            srcset: {
                hd: require("@/assets/videos/Mammography.mp4")
            }
        }
    },
    {
        id: "ed22bb05-ce6f-4ac7-98ea-9f934315fda6",
        text: [
            "As you watched the video, keep in mind a mammogram is an X-ray of the breast. It is really simple and takes only 10-15 minutes and is not invasive."
        ]
    },
    {
        id: "f9e25413-eade-4255-a5a7-a13448cd0820",
        text: [
            "If the mammogram shows a mass, you may need other tests such as ultrasonography, MRI, or biopsy for breast cancer diagnosis.",
            "If the test is positive, you will be referred to an oncology treatment team for breast cancer care."
        ]
    },
    {
        id: "1a6f4b6f-fd41-4690-a8bb-044d9007102d",
        text: [
            "There are two other breast cancer screening methods. Breast Self-Exam is one of the methods."
        ],
        question: {
            question: "Have you heard a term of Breast Self-Exam before?",
            answers: [
                "Yes",
                "No"
            ],
            correct: 0,
            time: 0,
            text: [
                [
                    "Good!",
                    "Please watch the video to see how it is done."
                ],
                [
                    "That is okay! I’ll show you what it is.",
                    "Please watch the video to see how it is done."
                ]
            ],
            video: {
                srcset: {
                    hd: require("@/assets/videos/Breast Self Examination.mp4")
                }
            }
        }
    },
    {
        id: "bfdef7d8-58b3-4678-90c7-fb43f5c47725",
        text: [
            "As you may notice, a breast self-exam is conducted when you want to check your own breasts for lumps, changes in size or shape of the breast, or any other changes in the breasts or armpit. Medical professionals recommend that every woman becomes familiar with her own breasts for health reasons."
        ]
    },
    {
        id: "382f6fce-6c42-434f-acff-bd3952aab760",
        text: [
            "A breast self-exam may be an option for women starting in their 20s.",
            "If you have a daughter, we encourage you to share this video with her to promote education and teach her how to do a self-breast exam.",
            "Please perform this exam every month after your period."
        ]
    },
    {
        id: "b1893278-cdb3-4206-9842-690a57555a1a",
        text: [
            "Another way that is frequently used for a breast cancer screening is a Clinical Breast Exam."
        ],
        question: {
            question: "Have you heard the term before?",
            answers: [
                "Yes",
                "No"
            ],
            correct: 0,
            time: 0,
            text: [
                [
                    "Wow! You must have had an interest in breast cancer screening.",
                    "Do you want to see how it should be done?"
                ],
                [
                    "Good to know!",
                    "I think the following video will help you know what a clinical breast exam is."
                ]
            ],
            video: {
                srcset: {
                    hd: require("@/assets/videos/cbe.mp4")
                },
                drive: {
                    sd: "1kMT4Z9QuidXXK2x5AEmE9m8g4_SkSIGX",
                    hd: "1kMT4Z9QuidXXK2x5AEmE9m8g4_SkSIGX",
                    uhd: "1kMT4Z9QuidXXK2x5AEmE9m8g4_SkSIGX"
                }
            }
        }
    },
    {
        id: "70bffb91-b7d6-465a-9e96-1e98b61a24da",
        text: [
            "Probably you noticed that a clinical breast exam is basically the same as a breast self-exam except for the fact that it is done by your health care providers such as a doctor or a nurse.",
            "Clinical breast exam may be optionally done about every 3 years for women in their 20s and 30s, and every year for women aged 40 or over.",
            "Both breast self-exam and clinical breast exam cannot detect an impalpable mass that may be breast cancer.",
            "Do you agree with that a mammogram is the best way to detect breast cancer?"
        ],
        pinkie: {
            src: require("@/assets/pinkie/worried.png"),
            right: true
        },
        question: {
            question: "In this case, the treatment gets ___ and the chance of survival gets ___.",
            answers: [
                "easier, higher",
                "tougher, lower"
            ],
            correct: 1,
            time: 0,
            text: [
                [
                    "Nice try",
                    "If you delay getting a mammogram or don’t receive it regularly, the treatment gets tougher, and surviving chance gets lower... 😢",
                    "Receiving a regular mammogram is helpful to minimize those risks!"
                ],
                [
                    "Yeah! You got it!!!",
                    "If you delay getting a mammogram or don’t receive it regularly, the treatment gets tougher, and surviving chance gets lower... 😢",
                    "Receiving a regular mammogram is helpful to minimize those risks!"
                ]
            ]
        }
    },
    {
        id: "6d8dd406-50bb-4a51-b818-2fc79fd63db1",
        text: [
            "If you want to chat more about today’s topic, text or call us back, tel:605-202-1418 605-202-1418.",
            "Our American Indian health navigator will answer your question right away.",
            "Like yesterday, you will receive a text later telling you how many pink ribbons you have received today."
        ],
        video: {
            srcset: {
                hd: require("@/assets/videos/Survivor YvonneR.mp4"),
            },
            drive: {
                sd: "1suihOx-4dSHt33HVWmxLAU8Q1V5V5lob",
                hd: "1w2Wg7rqK9RDRVb9QSmTqCOrq4dQntXHe",
                uhd: "17ffB_yoPEl4CSeLGob4Jm4jO4x12Fg7U"
            }
        }
    }
];

export const manager = new ContentManager(day2);

export default day2;